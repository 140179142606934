let ENV = {
  PROD: {
    WEB_URL: "https://app.servall.co.uk",
    API_URL: "https://server.servall.co.uk",

    //  API_URL: `http://${window.location.hostname}:4000`,
    // API_URL: "https://phpstack-920157-4175561.cloudwaysapps.com",
    SOCKET_URL: "",
    RESTAURANT_DATA_URL: "https://server.servall.co.uk/restaurant_data/",
    PANEL_URL: "",
    BASE_URL: "/",
    WEBSITE_URL: "https://qr.servall.co.uk",
    STRIPE_PUBLIC_KEY: "pk_test_51NuA45Dd4vQfvQK0klAFD2U2RG9klUMif89wOfEVcwCs2yAUGyXr7GIulkUgPRZk08y7k0UXUUtFoVWVfCNSKhkY00CPqj1qS5",
  },
  STAGE: {
    WEB_URL: "https://toc.idemos.website",
    API_URL: "https://phpstack-747976-2818115.cloudwaysapps.com",
    SOCKET_URL: "",
    PANEL_URL: "",
    BASE_URL: "/",
    STRIPE_PUBLIC_KEY:
        "pk_test_51NuA45Dd4vQfvQK0klAFD2U2RG9klUMif89wOfEVcwCs2yAUGyXr7GIulkUgPRZk08y7k0UXUUtFoVWVfCNSKhkY00CPqj1qS5",
  },
  DEV: {
    WEB_URL: "http://localhost:3002",
    // API_URL: `http://${window.location.hostname}:4000`,
    RESTAURANT_DATA_URL: `http://localhost:4000/restaurant_data/`,
    API_URL: `http://localhost:4000`,
    PANEL_URL: "http://localhost/restaurants",
    SOCKET_URL: "",
    BASE_URL: "/",
    WEBSITE_URL: "http://localhost:3000/restaurants",
    STRIPE_PUBLIC_KEY:
        "pk_test_51NuA45Dd4vQfvQK0klAFD2U2RG9klUMif89wOfEVcwCs2yAUGyXr7GIulkUgPRZk08y7k0UXUUtFoVWVfCNSKhkY00CPqj1qS5",
  },
};
let CONSTANTS = {
  BASE_URL: `${ENV[process.env.REACT_APP_STAGE].BASE_URL}`,
  PANEL_URL: `${ENV[process.env.REACT_APP_STAGE].PANEL_URL}`,
  API_URL: `${ENV[process.env.REACT_APP_STAGE].API_URL}`,
  RESTAURANT_URL: `${ENV[process.env.REACT_APP_STAGE].RESTAURANT_DATA_URL}`,
  WEB_URL: `${ENV[process.env.REACT_APP_STAGE].WEB_URL}`,
  WEBSITE_URL: `${ENV[process.env.REACT_APP_STAGE].WEBSITE_URL}`,
  SOCKET_URL: `${ENV[process.env.REACT_APP_STAGE].SOCKET_URL}`,
  CLOUD_URL: `${ENV["PROD"].API_URL}`,
  APP_TITLE: "ServAll Admin",
  CACHE_KEY_PREFIX: "tableordercart_admin",
  CACHE_KEY_PREFIX_ANALYTICS: "tableordercart_admin_location_analytics",
  WEB_PUSH_ID: process.env.REACT_APP_WEB_PUSH_ID,
  CLOUD_IMAGE_URL: process.env.REACT_APP_CLOUD_IMAGE_URL,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  STRIPE_PUBLIC_KEY: `${ENV[process.env.REACT_APP_STAGE].STRIPE_PUBLIC_KEY}`,
  REQUEST_HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  URLS: {
    LOGIN: "/sessions/create",
    OWNERLOGIN: "/sessions/owner/login",
    ORDERS: {
      DEFAULT: (locationId) => `/locations/${locationId}/orders`,
      LIVEORDER: "/orders/live",
      SESSIONS_LIST: (locationId) => `/locationSessions/all/${locationId}`,
      SESSIONS_DETAIL: (locationId, sessionId) =>
          `/locationSessions/${locationId}/detail/${sessionId}`,
      DIRECT_ORDERS: (locationId) => `/direct_orders/orders/${locationId}`,
      UPDATE_DIRECT_ORDERS: (order_id) =>
          `/direct_orders/orders/status/${order_id}`,
      SINGLE_DIRECT_ORDERS: (order_id) => `/direct_orders/${order_id}`,

      SESSIONS_LIST_EXPORT: (locationId) => `/locationSessions/export/${locationId}`,
      ORDER_ID: (OrderId) => `/delete/Order/${OrderId}`,
      CREATE_SESSION_ORDER: "/website/orders/create/order",
      GET_POS_ORDERS: "/manager/pos/order/all/orders",
      FINALIZE_ORDER_PAYMENT: (order_id) => `/manager/pos/order/finalize/orders/${order_id}`,
      CANCEL_POS_ORDER: (order_id) => `/manager/pos/order/remove/${order_id}`,
      MODIFY_ORDER: (order_id) => `/manager/pos/order/alter/${order_id}`,
    },

    USER_SUBSCRIPTIONS: {
      DEFAULT: "/user_subscriptions",
    },
    CONFIGURATIONS: {
      DEFAULT: "/configurations",
    },
    DIRECT_ORDERS: {
      DEFAULT: "/website/direct_orders/order",
    },
    LOCATIONS: {
      DEFAULT: "/locations",
      ALL_LOCATIONS: "/locations/all",
      DELETELOCATION: (location_id) => `/admin/remove/location/${location_id}`,
      CHECK_SLUG: "/locations/checkSlug",
      UPDATE_TIMINGS: (locationId) => `/locations/${locationId}/timings`,
      TERMS_AND_CONDITION: (location_id) =>
          `/termsConditions/${location_id}/createUpdate`,
      CHECKOUTMESSAGE: (location_id) => `/checkout_message/${location_id}`,
      CLOSE_SESSION: (session_id) => `/locationSessions/expire/${session_id}`,
      DELETE_SESSION: `/locationSessions/delete`,
      SYNC_DATA: "/admin/createrawsql/zip",
      BACKUP_LOCAL_DB: "/admin/createdb/backup",
      REFRESH_OTP: "/manager/refreshOTP",
      GET_SESSION_CARSINE: (session_id) => `/locationSessions/send/POS/detail/${session_id}`,
      GET_LOCATION_PAYMENT_METHODS: (loc_id) => `/payment_methods/location/method/${loc_id}`,
      UPDATE_LOCATION_PAYMENT_METHODS: (loc_id, method_id) => `/payment_methods/location/method/${loc_id}/update/${method_id}`,
      DELETE_LOCATION_PAYMENT_METHOD: (loc_id, method_id) => `/payment_methods/location/method/${loc_id}/delete/${method_id}`,
      ADD_NEW_PAYMENT_METHOD: (loc_id) => `/payment_methods/location/method/${loc_id}`,
      VERIFY_LOCATION: (location_id) => `locations/radius/${location_id}`
    },
    PACKAGES: {
      ALL_PACKAGES: "/website/packages/all",
      CREATE_ORDER: (license_key) =>
          `/website/packages/purchase/${license_key}`,
      GET_PACKAGE_BY_LOCATION: (locationId) =>
          `/packages/purchase/${locationId}`,
    },
    PURCHASE_HISTORY: {
      default: "/transactions/admin",
    },
    ALL_TRANSACTIONS: {
      default: "/transactions/owner",
    },
    ORDER_TYPES: {
      DEFAULT: (locationId) => `/locations/${locationId}/order_types`,
    },

    USERS: {
      DEFAULT: "/users",
      NEW_POS_USER: "/manager/pos/users/manage",
      GET_POS_USERS: (searchQuery)=> `/manager/pos/users/manage?searchQuery=${searchQuery}`,
      EDIT_POS_USER: (customer_id) => `/manager/pos/users/manage/${customer_id}`
    },
    PAYMENT_PROCESSORS: {
      DEFAULT: (locationId) => `/locations/${locationId}/payment_processors`,
    },

    ANALYTICS: {
      DEFAULT: (locationId, filter) =>
          `/locations/${locationId}/analytics/${filter}`,
      MANAGER_DASHBOARD: (location_id) =>
          `/locations/${location_id}/analytics/manager_dashboard`,
      OWNER_DASHBOARD: `/locations/analytics/dashboard/owner`,
      ADMIN_DASHBOARD: `/locations/analytics/dashboard/admin`,
      OVERALL_CO_ITEMS_REPORTS: (location_id) =>
          `/locations/${location_id}/analytics/items/report`,
      TABLE_SPENDING_STATS_LIST: (location_id) =>
          `/locations/${location_id}/analytics/table_spending_list`,
    },
    MENU: {
      DEFAULT: (locationId) => `/locations/${locationId}/menus`,
      GET_MENU: (slug) => `/website/locations/${slug}/menu`,
      BULK_UPDATE_PRIORITY: (locationId) =>
          `/locations/${locationId}/menus/bulkUpdate`,
      MENU_UPDATE_OPTIONS: (locationId, menuId) =>
          `/locations/${locationId}/menus/${menuId}/update`,
      GET_DUMMY_ITEM: (locationId) =>
          `/locations/${locationId}/menus/dummy_item`,
      GET_MODIFIERS: (locationId, menuId) =>
          `/locations/${locationId}/menus/${menuId}/modifiers`,
      GET_ALL_MODIFIERS: (locationId) =>
          `/locations/${locationId}/menus/all/modifiers`,
      GET_ALL_DISH_TAGS: (location_id) =>
          `/locations/${location_id}/dishtags/all`,
      UPDATE_DISH_TAG: (location_id, id) =>
          `/locations/${location_id}/dishtags/edit/${id}`,
      DELETE_DISH_TAG: (location_id, id) =>
          `/locations/${location_id}/dishtags/delete/${id}`,
      CREATE_DISH_TAG: (location_id) =>
          `/locations/${location_id}/dishtags/create`,
      GET_DISH_TAG: (location_id, id) =>
          `/locations/${location_id}/dishtags/show/${id}`,
      DELETE_SELECTED_DISH_TAGS: (location_id) =>
          `/locations/${location_id}/dishtags/deleteMultipleTags`,
      GET_ALL_ALLERGIC_ITEMS: (location_id) =>
          `/locations/${location_id}/allergy_items/all`,
      UPDATE_ALLERGIC_ITEM: (location_id, id) =>
          `/locations/${location_id}/allergy_items/edit/${id}`,
      CREATE_ALLERGIC_ITEM: (location_id) =>
          `/locations/${location_id}/allergy_items/create`,
      DELETE_ALLERGIC_ITEM: (location_id, id) =>
          `/locations/${location_id}/allergy_items/delete/${id}`,
      DELETE_SELECTED_ALLERGIC_ITEMS: (location_id) =>
          `/locations/${location_id}/allergy_items/deleteMultipleItems`,
      UPLOADMEDIA: (location_id, menu_id) =>
          `/locations/${location_id}/menus/upload_media/${menu_id}`,
      DELETEMEDIA: (location_id, menu_media_id) =>
          `/locations/${location_id}/menus/delete_media/${menu_media_id}`,
      UPDATEBULKTAGS: (location_id) =>
          `/locations/${location_id}/dishtags/bulk_update`,
      UPLOAD_PDF: (location_id) => `/locations/${location_id}/menus/upload_pdf`,
      GET_ALL_PDF_QRS: (location_id) =>
          `/locations/${location_id}/menus/getAllPdfQRs`,
      DELETE_PDF_QR: (location_id, id) =>
          `/locations/${location_id}/menus/PdfQR/${id}`,
      GET_ALL_POPUP_MENU: (location_id) =>
          `/locations/${location_id}/menus/popup_menu_list`,
    },
    MENU_OPTIONS: {
      DEFAULT: (locationId) => `/locations/${locationId}/options`,
      FILE: (locationId) => `/locations/${locationId}/options/file`,
    },
    CATEGORY: {
      DEFAULT: (locationId) => `/locations/${locationId}/categories`,
      GET: (locationId) => `/locations/${locationId}/categories/get`,
      BULK_UPDATE_PRIORITY: (locationId) =>
          `/locations/${locationId}/categories/bulkUpdate`,
    },
    PROMOTION: {
      DEFAULT: (locationId) => `/locations/${locationId}/promotions`,
    },
    REVIEWS: {
      DEFAULT: (locationId) => `/admin/${locationId}/reviews`,
      DELETE: (locationId) => `/admin/${locationId}/reviews/delete`,
    },
    RESERVATIONS: {
      GET_ALL_RESERVATIONS: `/website/reservations/get/reservations`,
      ADD_NEW_RESERVATION: (location_id) => `/website/reservations/add/reservation/${location_id}`,
      UPDATE_RESERVATION: (location_id, reservation_id) => `/website/reservations/status/accepted/reservation/${location_id}/${reservation_id}`,
      DELETE_RESERVATION: (reservation_id) => `/website/reservations/delete/reservation/${reservation_id}`,
      UPDATE_RESERVATION_STATUS: (status, reservation_id) => `/website/reservations/status/${status}/reservation/${reservation_id}`,
    },
    ADMIN: {
      DEFAULT: "/admin/users",
      UPDATE_BAN: "/admin/users/ban",
      ALL_REPORTS: "/admin/users/reports",
      DEL_REPORT: "/admin/users/reports/delete",
      ALL_FEEDBACKS: "/admin/feedbacks",
      ALL_COUNTRIES: "/admin/countries",
      TABLES: {
        DEFAULT: (locationId) => `/admin/${locationId}/tables`,
        GET_TABLE: (locationId, tableId) =>
            `/admin/${locationId}/tables/${tableId}`,
        STATUSES: (locationId) => `/admin/${locationId}/tables/status`,
        DELETE_TABLES: (locationId, floor_id) =>
            `/admin/${locationId}/tables/delete/${floor_id}`,
        STATUSESID: (locationId, table) =>
            `/admin/${locationId}/tables/status/${table}`,
        QR: (locationId) => `/admin/${locationId}/tables/qr`,
        UNLINKTAB: (locationId, table) =>
            `/admin/${locationId}/tables/unlinktab/${table}`,
        GET_TAB_LINKED_TABLES: (location_id) =>
            `/locationSessions/linkedTables/${location_id}`,
        SEND_NOTIFICATION_TO_SELECTED_TABS: (location_id) =>
            `/locationSessions/linkedTables/${location_id}/send_popup_triggers`,
        SESSION_TABLE_STATUSES: (location_id) =>
            `/locationSessions/view/${location_id}`,
        GET_TABLE_BY_FLOOR: (locationId, floor_id) =>
            `/admin/${locationId}/tables/floor/${floor_id}`,
      },
      EVENTS: {
        DEFAULT: (locationId) => `/events/${locationId}`,
        DELETEEVENT: `/events/remove`,
        CHANGESTATUS: (eventId) => `/events/status/${eventId}`,
        CREATEEVENT: "/events",
      },
      HOTELREVIEWS: {
        DEFAULT: (locationId) => `/review/hotels/location/${locationId}`,
        APPROVEREVIEW: (reviewId) => `/review/hotels/approve/${reviewId}`,
        REJECTREVIEW: (reviewId) => `/review/hotels/reject/${reviewId}`,
        DELETEREVIEW: (reviewId) => `/review/hotels/delete/${reviewId}`,
      },
      TIMESLOTS: {
        DEFAULT: "/timeslots/locations/all",
        CREATE: "/timeslots/locations/slots",
        UPDATE_DAY: "/timeslots/locations/update",
        UPDATE_SLOT: (slot_id, status) =>
            `/timeslots/locations/change-status/slot/${slot_id}/${status}`,
        CHANGE_DAY_STATUS: (time_id, status) =>
            `/timeslots/locations/change-status/time/${time_id}/${status}`,
        GET_EACH_DAY_TIMESLOT: (day) => `/timeslots/locations/all/slots/${day}`,
      },

      FLOORS: {
        DEFAULT: (locationId) => `/admin/${locationId}/floors/display`,
        SAVE_FLOOR: (locationId) => `/admin/${locationId}/floors/save`,
        DELETE_FLOOR: (locationId, floor_id) =>
            `/admin/${locationId}/floors/${floor_id}`,
      },
      PAYMENT_METHODS: {
        // DEFAULT: '/admin/',
        DEFAULT: (locationId) => `/admin/payment_method/${locationId}`,
        SAVE_RAYMENT: (locationId) => `/admin/payment_method/${locationId}`,
        GET_PAYMENT_ID: (id) => `/admin/payment_method/record/${id}`,
        UPDATE_PAYMENT: (id) => `/admin/payment_method/${id}`,
        DELETE: (id) => `/admin/payment_method/delete/${id}`,
      },
      ROOMS: {
        GET_ALL: "/hotels/all-rooms",
        CREATE_ROOM: (loc_id) => `/hotels/rooms/${loc_id}`,
        GET_ROOM_BY_LOC: (loc_id) => `/hotels/rooms/${loc_id}`,
        GET_ROOM_BY_ID: (room_id) => `/hotels/room/${room_id}`,
        DELETE_ROOM: (room_id) => `/hotels/room/${room_id}`,
        UPDATE_ROOM: (room_id) => `/hotels/room/${room_id}`,
        SESSION_ROOM_STATUSES: (location_id) =>
            `/locationSessions/view/rooms/${location_id}`,
      },
      CURRENCY: {
        DEFAULT: "/admin/currencies",
        GET_CURRENCY: (currencyId) => `/admin/currencies/${currencyId}`,
        STATUSES: "/admin/currencies/status",
      },
      STAFF: {
        DEFAULT: "/admin/staff",
        GET_STAFF: (staffId) => `/admin/staff/${staffId}`,
        GET_ALL_STAFF: `/admin/staff/all`,
        STATUSES: "/admin/staff/status",
        ROLES: "/admin/staff/roles",
        ASSIGN: "/admin/staff/assign",
        DEL: "/admin/staff/del",
        SEARCH: "/admin/staff/search",
        CUSTOMERLIST: "/admin/staff/customers",
        GETPRICINGLIST: "/packages/get_all",
        GETPRODUCTPRICINGLIST: "/packages/product/pricing",
        CREATEPRICINGLIST: "/packages/create",

        GETPACKAGEPRODUCTCATEGORY: "/packages/categories",
        CREATEPACKAGEPRODUCTCATEGORY: "/packages/product/price",
        UPDATEPACKAGEPRICING: (productId) => `/packages/update/${productId}`,

        UPDATEPACKAGEPRODUCTCATEGORY: (productId) =>
            `/packages/product/update_price/${productId}`,
      },

      ROLES: {
        DEFAULT: "/admin/roles",
        GET_ROLE: (roleId) => `/admin/roles/${roleId}`,
        STATUSES: "/admin/roles/status",
      },
      BANNERS: {
        GET_ALL: (location_id) => `/locations/${location_id}/banners/get`,
        DELETE_BANNER: (location_id, id) =>
            `/locations/${location_id}/banners/${id}`,
        SAVE_BANNER: (location_id) =>
            `/locations/${location_id}/banners/createUpdate`,
      },
      BRANDING: {
        DEFAULT: (location_id) => `/locations/${location_id}/layouts`,
      },
      NOTIFICATION: {
        DEFAULT: (location_id, page) =>
            `/locationSessions/allNotifications/${location_id}?page=${page}`,
        UPDATE_STATUS: (loc_id, notification_id) =>
            `/locationSessions/${loc_id}/notificationSeen/${notification_id}`,
      },
      SURVEY: {
        CREATE_SURVEY: `/survey/create`,
        GET_SURVEY: (location_id, page) =>
            `/survey/${location_id}/all_questions?page=${page}`,
        UPDATE_SURVEY_STATUS: (location_id) =>
            `/survey/${location_id}/update_status`,
        CREATE_QUESTION: (location_id) => `/survey/${location_id}/create`,
        UPDATE_QUESTION: (location_id, question_id) =>
            `/survey/${location_id}/edit/${question_id}`,
        DELETE_QUESTION: (location_id, question_id) =>
            `/survey/${location_id}/delete/${question_id}`,
        GET_ALL_RESPONSE: (location_id, page) =>
            `/survey/${location_id}/get_all/survey_response?page=${page}`,
      },
      PREMIUM_SERVICES: {
        GET_LOCATION_PREMIUM_SERVICES: (loc_id) => `/admin/services//all/${loc_id}`,
        UPDATE_LOCATION_PREMIUM_SERVICES: (loc_id, premium_service_id) => `/admin/services/update/location/${loc_id}/service/${premium_service_id}`,
        DELETE_LOCATION_PREMIUM_SERVICES: (service_id) => `/admin/services/remove/service/${service_id}`,
        ADD_LOCATION_PREMIUM_SERVICES: (loc_id) => `/admin/services/add/service/${loc_id}`,
      }
    },
    FORGETPASSEMAIL: {
      default: "/sessions/forget_password_request",
    },
    RESETPASSEMAIL: {
      default: "/sessions/reset_password",
    },
    SENDRECEIPTEMAIL: {
      default: (location_id) => `/locationSessions/send_receipt/${location_id}`,
    },
    SESSION_ORDERS: {
      CREATE_SESSION_ORDER: "/website/orders/create/session",
      GET_SESSION_STATUS: (session_id) => `/website/orders/sessionStatus/${session_id}`,
    },
    POS_REGISTER: {
      SAVE_REGISTER_DETAILS: "/manager/pos/register/open",
      CHECK_REGISTER: "/manager/pos/register/get",
      CLOSE_REGISTER: (register_id) => `/manager/pos/register/close/${register_id}`
    }
  },
  ACTIONS: {
    CART: {
      ADD_ITEMS_TO_CART: "ADD_ITEMS_TO_CART",
      GET_CART_TOTAL: "GET_CART_TOTAL",
      CLEAR_CART: "CLEAR_CART",
      GET_ALL_AMOUNTS: "GET_ALL_AMOUNTS",
      REMOVE_ITEM: "REMOVE_ITEM",
      UPDATE_ITEMS_IN_CART: "UPDATE_ITEMS_IN_CART"
    },

    ANALYTICS: {
      OVERALL: "GET_OVERALL_STATS",
      CUSTOMERS: "GET_CUSTOMER_STATS",
      ITEMS: "GET_ITEM_STATS",
      DASHBOARD: "DASHBOARD",
    },
    CATEGORIES: {
      CATEGORY_MENU_UPDATE: "UPDATE_CATEGORY_WITH_MENU",
      CATEGORY_MENU: "GET_CATEGORY_WITH_MENU",
      GET_CATEGORY: "GET_CATEGORY",
    },
    PROMOTIONS: {
      ALL_PROMOTIONS: "GET_ALL_PROMOTIONS",
      GET_PROMOTION: "GET_PROMOTION",
    },
    MENU_OPTIONS: {
      GET_ALL: "GET_ALL_MENU_OPTIONS",
      SAVE: "SAVE_MENU_OPTION",
      DELETE: "DELETE_MENU_OPTION",
      STATUS: "STATUS_MENU_OPTION",
    },
    MENU: {
      BULK_UPDATE: "BULK_UPDATE_MENU",
      GET: "GET_MENU",
      CREATE: "CREATE_MENU",
      DELETE: "DELETE_MENU",
      GET_DUMMY_ITEM: "GET_DUMMY_ITEM",
      GET_ALL_MODIFIERS: "GET_ALL_MODIFIERS",
      GET_ALL_DISH_TAGS: "GET_ALL_DISH_TAGS",
      UPDATE_DISH_TAG: "UPDATE_DISH_TAG",
      DELETE_DISH_TAG: "DELETE_DISH_TAG",
      CREATE_DISH_TAG: "CREATE_DISH_TAG",
      GET_ALL_ALLERGIC_ITEMS: "GET_ALL_ALLERGIC_ITEMS",
      UPLOAD_PDF: "UPLOAD_PDF",
      GET_ALL_PDF_QRS: "GET_ALL_PDF_QRS",
      DELETE_PDF_QR: "DELETE_PDF_QR",
    },
    ORDERS: {
      LIST: "GET_LIVE_ORDERS",
      GET: "GET_ORDER",
      SESSIONS_LIST: "SESSIONS_LIST",
    },
    CONFIGURATIONS: {
      STATUSES: "GET_STATUSES",
      CURRENCIES: "GET_CURRENCIES",
      CATEGORIES: "GET_CATEGORIES",
    },
    LOCATIONS: {
      GET: "GET_LOCATION",
    },
    USERS: {
      ALL_USERS: "GET_ALL_USERS",
      ALL_REPORTS: "GET_ALL_REPORTS",
    },
    FEEDBACKS: {
      ALL_FEEDBACKS: "GET_ALL_FEEDBACKS",
    },
    COUNTRIES: {
      ALL_COUNTRIES: "GET_ALL_COUNTRIES",
    },
    CURRENCIES: {
      ALL_CURRENCIES: "GET_ALL_CURRENCIES",
    },
    ROLES: {
      ALL_ROLES: "GET_ALL_ROLES",
    },
    HOTELREVIEWS: {
      GET_ALL: (locationId) => `/review/hotels/location/${locationId}`,
    },
    STAFF: {
      ALL_STAFF: "GET_ALL_STAFF",
      ALL_ROLES: "GET_ALL_ROLES",
      All_PRODUCT_PRICING: "GETPRODUCTPRICINGLIST",
      All_PRICING: "GETPRICINGLIST",
      ALL_PACKAGE_PRODUCT_CATEGORY: "GETPACKAGEPRODUCTCATEGORY",
      DEFAULT: "GET_STAFF",
    },
    REVIEWS: {
      ALL_REVIEWS: "GET_ALL_REVIEWS",
    },
    TABLES: {
      ALL_TABLES: "GET_ALL_TABLES",
      CONNECTED_TABLES: "CONNECTED_TABLES",
      ALL_SESSIONS_TABLES: "GET_ALL_SESSION_TABLES",
    },
    FLOORS: {
      ALL_FLOORS: "GET_ALL_FLOORS",
    },
    ROOMS: {
      GET_ALL: "GET_ALL",
      ALL_SESSIONS_ROOMS: "GET_ALL_SESSION_ROOMS",
      CREATE_ROOM: (loc_id) => `/website/hotels/rooms/${loc_id}`,
      GET_ROOM_BY_LOC: (hotel_id) => `/website/hotels/rooms/${hotel_id}`,
      GET_ROOM_BY_ID: (room_id) => `/website/hotels/room/${room_id}`,
      UPDATE_ROOM: (room_id) => `/website/hotels/room/${room_id}`,
      DELETE_ROOM: (room_id) => `/website/hotels/room/${room_id}`,
    },

    NOTIFICATION: {
      GET_ALL: "GET_ALL",
    },
    SURVEYQUESTIONS: {
      GET_ALL: "GET_ALL_SURVEY_QUESTIONS",
    },

    SURVEYRESPONSE: {
      GET_ALL: "GET_ALL_SURVEY_RESPONSE",
    },
    PAYMENT_METHODS: {
      GET: "GET_LOCATION_PAYMENT_METHODS",
    },
    PREMIUM_SERVICES: {
      GET_PREMIUM_SERVICES: "GET_LOCATION_PREMIUM_SERVICES",
      UPDATE_PREMIUM_SERVICES: "UPDATE_LOCATION_PREMIUM_SERVICES",
      DELETE_PREMIUM_SERVICES: "DELETE_LOCATION_PREMIUM_SERVICES",
      ADD_PREMIUM_SERVICES: "ADD_LOCATION_PREMIUM_SERVICES",
    },
    LOADING: "LOADING",
    RESERVATIONS: {
      GET_ALL: "GET_ALL_RESERVATIONS",
    },
    POS: {
      SAVE_CUSTOMER_DATA: "SAVE_CUSTOMER_DATA",
      LOCATION_DATA: "LOCATION_DATA",
      ORDER_TYPE: "ORDER_TYPE",
      USER_LOCATION_DATE: "USER_LOCATION_DATA",
      ALL_TABLES: "ALL_TABLES",
      SELECTED_TABLE: "SELECTED_TABLE",
      CUSTOMER_TYPE: "CUSTOMER_TYPE",
      GET_POS_ORDERS: "GET_POS_ORDERS",
      SET_FILTERED_POS_ORDERS: "SET_FILTERED_POS_ORDERS",
      SET_REGISTER_INFO: "SET_REGISTER_INFO",
      POS_SELECTED_CATEGORY: "POS_SELECTED_CATEGORY",
      SET_SELECTED_ORDER: "SET_SELECTED_ORDER",
      SET_IS_MODIFY_ORDER: "SET_IS_MODIFY_ORDER",
      SET_REMOVE_ITEMS: "SET_REMOVE_ITEMS",
      CLEAR_SELECTED_USER: "CLEAR_SELECTED_USER",
    }
  },
  SETTINGS: {
    STATUS_POLLING_INTERVAL: 10000,
  },
  RATING_COLORS: {
    0: "#F90403",
    1: "#F90403",
    2: "#FFA400",
    3: "#92D050",
    4: "#00EAD9",
    5: "#00EAD9",
  },
  DATES: {
    FORMAT: {
      DISPLAY: "Do MMM, YYYY",
      DB_DATE: "YYYY-MM-DD",
      EVENT_DISPLAY: "ddd,Do MMM, YYYY",
    },
  },
  TIMES: {
    FORMAT: {
      DISPLAY: "hh:mm A",
      DB: "HH:mm:ss",
      PICKER: "H:i",
    },
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: "DD MMM hh:mm A",
      DISPLAYYEAR: "DD MMM YYYY hh:mm A",
      DB: "YYYY-MM-DD HH:mm:ss",
    },
  },
  VARIABLES: {
    ORDER_TYPES: {
      DINE_IN: "dine_in",
      PICK_UP: "pick_up",
      CURBSIDE_PICKUP: "curbside_pickup",
      DELIVERY: "delivery",
    },
    ORDERS: {
      DEFAULT_PAGE_LENGTH: 100,
      ASAP_LABEL: "ASAP",
      STATUSES: {
        PLACED: "Placed",
        SEEN: "Seen",
        BEING_PREPARED: "Being Prepared",
        READY: "Ready For Pickup",
        ON_THE_WAY: "On The Way",
        COMPLETE: "Complete",
        CANCELLED: "Cancelled",
      },
      REASONS_TO_CANCEL: [
        "Customer changed their mind",
        "Items were not available",
        "Duplicate order by customer",
      ],
      PAYMENT_STATUSES: {
        PAID: "paid",
      },
      PAYMENT_METHODS: {
        scheme: "Card",
        cash: "Cash",
        default: "Cash",
      },
      ORDER_TYPE_STATUSES: {
        DINE_IN: [11, 12, 13, 14, 15, 19],
        PICK_UP: [11, 12, 13, 21, 15, 19],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19],
      },
      ORDER_STATUS_IDS: {
        BEING_PREPARED: 13,
        CANCELLED: 19,
      },
      WARNINGS: {
        UN_PAID: "unpaid",
        CANCELLED: "cancel",
      },
      EVENTS: {
        NEW: "order_recieved",
        UPDATE: "order_update",
        BILL_REQUESTED: "bill_requested",
      },
    },
    MENUS: {
      OPTIONS: {
        DISPLAY_TYPES: {
          RADIO: "radio",
          SELECT: "select",
          CHECKBOX: "checkbox",
        },
      },
      TYPE: {
        KITCHEN: "Kitchen",
        BAR: "Drinks/Bar",
        DESSERT: "Dessert/Sweets",
        ALTERNATIVE_1: "Alternative_1",
        ALTERNATIVE_2: "Alternative_2",
      },
    },
    PAYMENTS: {
      DEFAULT: "CyberSource",
    },
    ANALYTICS: {
      OVERALL_STATS_CHART: {
        TOTAL_SALES: "Total Sales",
        COMPLETED_ORDERS: "Completed Orders",
        CANCELLED_ORDERS: "Cancelled Orders",
      },
    },
    PROMOTIONS: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0,
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        EVERY_DAY_SPECIFIC_TIMES: 3,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2,
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2,
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1,
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1,
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1,
        },
      },
    },
    LOCATIONS: {
      OPENING_TYPE: {
        ALL_TIME: 0,
        DAILY: 1,
        FLEXIBLE: 2,
      },
      PICKUP_HOURS: {
        SAME_AS_OPENING: 0,
        ALL_TIME: 1,
        DAILY: 2,
        FLEXIBLE: 3,
      },
    },
  },
  FIREBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  FILTERS: {
    ORDERS: [
      { name: "Live Orders", key: "live_orders", default: true },
      { name: "Closed Orders", key: "closed_orders", default: false },
      { name: "All Orders", key: "all", default: false },
    ],
  },
  SORTS: {
    ORDERS: [
      { name: "Most Recent First", key: "DESC", default: true },
      { name: "Oldest First", key: "ASC", default: false },
    ],
  },
  RANDOM: {
    MIN: 1000000,
    MAX: 99999999,
  },
  EVENT_TIMINGS: {
    opening_type_value: 0,
    opening_type: "24/7",
    daily_days: ["0", "1", "2", "3", "4", "5", "6"],
    daily_hours: { open: "00:00 AM", close: "11:59 PM" },
    flexible_hours: [
      {
        day: "0",
        open: "12:00 AM",
        close: "11:59 PM",
        status: true,
      },
      {
        day: "1",
        open: "12:00 PM",
        close: "01:59 PM",
        status: true,
      },
      {
        day: "2",
        open: "10:45 AM",
        close: "23:59:00",
        status: true,
      },
      {
        day: "3",
        open: "12:00 AM",
        close: "11:59 PM",
        status: true,
      },
      {
        day: "4",
        open: "12:00 AM",
        close: "11:59 PM",
        status: true,
      },
      {
        day: "5",
        open: "12:00 AM",
        close: "11:59 PM",
        status: true,
      },
      {
        day: "6",
        open: "12:00 AM",
        close: "11:59 PM",
        status: true,
      },
    ],
  },
  DUMMY_WORKING_HOURS: [
    {
      id: 0,
      weekday: 0,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 1,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 2,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 3,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 4,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 5,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 6,
      location_id: 0,
      type: "opening",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 0,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 1,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 2,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 3,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 4,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 5,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
    {
      id: 0,
      weekday: 6,
      location_id: 0,
      type: "collection",
      status: true,
      opening_time: "00:00:00",
      closing_time: "23:59:00",
    },
  ],
  DUMMY_ORDER_TYPES: [
    {
      order_type: "dine_in",
      label: "Dine In",
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false,
    },
    {
      order_type: "pick_up",
      label: "Pick up",
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false,
    },
    {
      order_type: "curbside_pickup",
      label: "CURBSIDE",
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false,
    },
  ],
  DUMMY_VALUES: {
    auto_lat_lng: "1",
    opening_hours: {
      opening_type: "24/7",
      daily_days: ["0", "1", "2", "3", "4", "5", "6"],
      daily_hours: {
        open: "07:30 AM",
        close: "11:00 PM",
      },
      flexible_hours: [
        {
          day: "0",
          open: "12:00 AM",
          close: "11:59 PM",
          status: "1",
        },
        {
          day: "1",
          open: "12:00 AM",
          close: "11:59 PM",
          status: "1",
        },
        {
          day: "2",
          open: "12:00 PM",
          close: "2:00 AM",
          status: "1",
        },
        {
          day: "3",
          open: "3:15 PM",
          close: "2:00 AM",
          status: "1",
        },
        {
          day: "4",
          open: "3:00 PM",
          close: "2:00 AM",
          status: "1",
        },
        {
          day: "5",
          open: "12:00 AM",
          close: "11:59 PM",
          status: "1",
        },
        {
          day: "6",
          open: "12:00 AM",
          close: "11:59 PM",
          status: "1",
        },
      ],
      collection_type: "0",
      collection_days: ["0", "1", "2", "3", "4", "5", "6"],
      collection_hours: {
        open: "12:00 AM",
        close: "11:59 PM",
      },
    },
    future_orders: "0",
    future_order_days: {
      collection: "5",
    },
    gallery: {
      title: "",
      description: "",
    },
    currency_id: "",
  },
  DUMMY_OPTIONS: {
    auto_lat_lng: "1",
    opening_hours: {
      opening_type: "24/7",
      daily_days: ["0", "1", "2", "3", "4", "5", "6"],
      daily_hours: {
        open: "09:00 PM",
        close: "11:59 PM",
      },
      flexible_hours: [
        {
          day: "0",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "1",
          open: "12:00 PM",
          close: "01:59 PM",
          status: true,
        },
        {
          day: "2",
          open: "10:45 AM",
          close: "23:59:00",
          status: false,
        },
        {
          day: "3",
          open: "12:00 AM",
          close: "11:59 PM",
          status: false,
        },
        {
          day: "4",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "5",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "6",
          open: "21:00:00",
          close: "23:59:00",
          status: true,
        },
      ],
      collection_type: "0",
      collection_days: ["0", "1", "2", "3", "4", "5", "6"],
      collection_hours: {
        open: "04:00 PM",
        close: "06:59 PM",
      },
      events: [],
      event_holidays: [],

      pickup_flexible_hours: [
        {
          day: "0",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "1",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "2",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "3",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "4",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "5",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
        {
          day: "6",
          open: "12:00 AM",
          close: "11:59 PM",
          status: true,
        },
      ],
    },
    future_orders: "0",
    future_order_days: {
      collection: "5",
    },
    gallery: {
      title: "",
      description: "",
    },
    currency_id: "1",
  },
};

export default CONSTANTS;
